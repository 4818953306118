import { t } from 'i18next'
import * as R from 'ramda'
import { checkIfDx } from '../../../nemo/offer/assessment-selection'
import {
  EquipmentGroup,
  Equipment,
  Offer,
  Building,
  GroupKind,
  OpportunityEquipment,
  OpportunityBuilding
} from '../../../../../types/types'
import { EquipmentCategory } from '../../../../../../package-pricing'
import { getUUID } from '../../../../../common/common-utils'
import { isDoorsOpportunity } from '../../../../../common/opportunity'
import { CurrentOffer } from '../../../state/current-offer'
import { updateRecommendedOffer } from '../../../nemo/offering/recommendation'
import { FrontlineConfig } from '../../../state/frontline-config'

export const cleanString = (value: string) => value.replace(/\s+/g, ' ').trim()

export function isEquipmentGroupNameValid(name: string, groups: EquipmentGroup[], id?: string): boolean {
  return (
    name.length > 0 &&
    name.length <= 50 &&
    R.pipe(
      R.filter((group: EquipmentGroup) => id !== group.salesToolId),
      R.none((group: EquipmentGroup) => name.toLowerCase() === group.name.toLowerCase())
    )(groups)
  )
}

export function partitionByEquipmentCategory(equipment: Equipment[]): Equipment[][] {
  return R.filter(
    R.complement(R.isEmpty),
    R.partition(e => e.equipmentCategory !== 'resiflow', equipment)
  )
}

export function groupKindForCategory(equipmentCategory: EquipmentCategory): GroupKind {
  switch (equipmentCategory) {
    case 'door':
      return 'doors'
    case 'elevator':
    case 'escalator':
      return 'elevators_and_escalators'
    case 'resiflow':
      return 'people_flow'
  }
}

export const getGroupKindsForBuilding = (building: Building): Record<GroupKind, Building> =>
  R.map(
    (e: Equipment[]) => ({ ...building, details: { ...building.details, equipment: e } }),
    R.groupBy(e => groupKindForCategory(e.equipmentCategory), building.details.equipment) as Record<
      GroupKind,
      Equipment[]
    >
  )

const updateGroup =
  (newEqGrp: EquipmentGroup) =>
  (offer: Offer): Offer =>
    updateRecommendedOffer(
      {
        ...offer,
        equipmentGroups: {
          ...offer.equipmentGroups,
          [newEqGrp.salesToolId]: newEqGrp
        }
      },
      FrontlineConfig.get()
    )

export const updateDXGroup =
  (newEqGrp: EquipmentGroup, existingOffer: Offer) =>
  (offer: Offer): Offer =>
    updateRecommendedOffer(
      {
        ...existingOffer,
        dxGrpId: newEqGrp.salesToolId,
        equipmentGroups: {
          ...offer.equipmentGroups,
          [newEqGrp.salesToolId]: newEqGrp
        }
      },
      FrontlineConfig.get()
    )

export const getDefaultGroupForCategory = (category: EquipmentCategory, offer: Offer) => {
  const defaultGroup = Object.values(offer.equipmentGroups).find(
    eg => eg.groupKind === groupKindForCategory(category) && eg.salesToolId !== offer.dxGrpId
  )
  if (!defaultGroup) {
    if (category === 'elevator' || category === 'escalator') {
      const getGroupNonDx = createNewGrps(
        false,
        isDoorsOpportunity(offer.opportunity),
        isVasOpportunity(offer.opportunity.opportunityCategory)
      )
      CurrentOffer.update(updateGroup(getGroupNonDx))
      return getGroupNonDx.salesToolId
    }
    throw new Error(t('xlAssessmentWizard.equipment.default.group', { category }))
  }
  return defaultGroup.salesToolId
}

export const doesDefaultGroupExist = (category: EquipmentCategory, offer: Offer) => {
  try {
    getDefaultGroupForCategory(category, offer)
  } catch (e) {
    return false
  }
  return true
}

export const groupHasEquipment = (id: string, buildings: Building[]) =>
  buildings.map(b => b.details.equipment.some(e => e.groupId === id)).some(Boolean)

export const isGroupOfKind =
  (groupKind: GroupKind) =>
  (eg: EquipmentGroup): boolean =>
    groupKind === eg.groupKind

export const isPeopleFlowGroup = isGroupOfKind('people_flow')

export const isElevatorsAndEscalatorsGroup = isGroupOfKind('elevators_and_escalators')

export const isVasOpportunity = (opportunityCategory: string) => opportunityCategory === 'Value Added Service'

export const createNewGrps = (isDx: boolean, isDoor: boolean, isVas: boolean = false) => ({
  name: isDx
    ? t('equipment.group.dxGroup.name')
    : isDoor || isVas
    ? t('equipment.group.default')
    : t('equipment.group.nonDxGroup.name'),
  salesToolId: getUUID(),
  groupKind: isDoor ? 'doors' : ('elevators_and_escalators' as GroupKind),
  isDx
})

const checkIfDXinOppOrOffer = (buildings: Building[] | OpportunityBuilding[], isDXCheck: boolean, isOpp: boolean) => {
  const hasDXOpp: OpportunityEquipment[] = []
  const hasNonDXOpp: OpportunityEquipment[] = []
  const hasDXOffer: Equipment[] = []
  const hasNonDXOffer: Equipment[] = []
  if (isOpp) {
    const opBulding = buildings as OpportunityBuilding[]
    opBulding.forEach(b =>
      b.equipment.find(e => {
        isDXCheck ? checkIfDx(e) && hasDXOpp.push(e) : !checkIfDx(e) && hasNonDXOpp.push(e)
      })
    )
  }
  const offerBuilding = buildings as Building[]
  offerBuilding.forEach(b =>
    b.details.equipment.find(e => {
      isDXCheck ? checkIfDx(e) && hasDXOffer.push(e) : !checkIfDx(e) && hasNonDXOffer.push(e)
    })
  )
  return {
    hasDXOpp,
    hasNonDXOpp,
    hasDXOffer,
    hasNonDXOffer
  }
}

// This method checks what how many default groups need to be created based on eqyuipment type and category
export const checkGroupAdd = (offer: Offer) => {
  const hasDXEqinOpp = checkIfDXinOppOrOffer(offer.opportunity.buildings, true, true).hasDXOpp.length > 0
  const hasDXEqInOffer = checkIfDXinOppOrOffer(offer.buildings, true, false).hasDXOffer.length > 0
  const hasNonDXEqInOpp = checkIfDXinOppOrOffer(offer.opportunity.buildings, false, true).hasNonDXOpp.length > 0
  const hasNonDXEqInOffer = checkIfDXinOppOrOffer(offer.buildings, false, false).hasNonDXOffer.length > 0
  const isVas = isVasOpportunity(offer.opportunity.opportunityCategory)
  const isDoorOrVas = isDoorsOpportunity(offer.opportunity) || isVas

  if (!isDoorOrVas) {
    if ((hasDXEqinOpp || hasDXEqInOffer) && !hasNonDXEqInOpp && !hasNonDXEqInOffer) {
      const getGrp = createNewGrps(true, false)
      return {
        [getGrp.salesToolId]: getGrp
      }
    } else if ((hasDXEqinOpp || hasDXEqInOffer) && (hasNonDXEqInOpp || hasNonDXEqInOffer)) {
      const getGrp = createNewGrps(true, false)
      const getGrpNonDX = createNewGrps(false, false)
      return {
        [getGrpNonDX.salesToolId]: getGrpNonDX,
        [getGrp.salesToolId]: getGrp
      }
    } else if (!hasDXEqinOpp && !hasDXEqInOffer && (hasNonDXEqInOpp || hasNonDXEqInOffer)) {
      const getGrp = createNewGrps(false, false)
      return {
        [getGrp.salesToolId]: getGrp
      }
    }
  }
  const defaultGrp = createNewGrps(false, false, isVas)
  return {
    [defaultGrp.salesToolId]: defaultGrp
  }
}
